<template>
  <div class="blocks">

    <section class="block">
      <section>
        <h3 v-text="text.title"></h3>
        <p v-text="text.intro"></p>
      </section>
      <section class="form">
        <component v-bind:is=" get_component_name( form_field.type )" v-for="form_field in form_fields" :key="form_field.name"
                   v-bind:options="form_field.options" v-bind:label="form_field.label" v-bind:name="form_field.name" v-bind:value="form_field.value" v-bind:info="form_field.info"
                   v-on:changed="update_field" v-bind:store="'activity'"
          ></component>
      </section>
    </section>

    <section class="block">
      <section>
        <h3 v-text="text.affected_title"></h3>
        <p v-text="text.affected_intro"></p>
      </section>
      <section class="form">
        <component v-bind:is=" get_component_name( form_field.type )" v-for="form_field in affected_form_fields" :key="form_field.name"
                   v-bind:options="form_field.options" v-bind:label="form_field.label" v-bind:name="form_field.name" v-bind:value="form_field.value" v-bind:info="form_field.info"
                   v-on:changed="update_field" v-bind:store="'activity_affected'"
        ></component>
      </section>
      <section class="form">
        <Form_Summary label="Total number of people affected:" :value="total_affected"></Form_Summary>
      </section>
      <section class="button_row">
        <!--router-link :to="{ name: 'hazards'}" role="button" event="" class="primary" @click.native="save_step">Continue</router-link-->
        <a href="#" role="button" class="primary" v-bind:class="{ loading: is_loading }" @click.prevent="save_step" v-text="next_step_text"></a>
        <router-link :to="{ name: 'personal_details'}" role="button">Back</router-link>
      </section>
    </section>

  </div>
</template>

<script>
import Input_Number from "@/components/Input_Number";
import Input_Textarea from "@/components/Input_Textarea";
import Input_Text from "@/components/Input_Text";
import Input_Select from "@/components/Input_Select";
import Input_Date from "@/components/Input_Date";
import Form_Summary from "@/components/Form_Summary";
import store from "@/store/store";

export default {
  name: "Activity",
  components: { Form_Summary, Input_Number, Input_Textarea, Input_Text, Input_Select, Input_Date },
  data: function() {
    return {
      is_loading: false,
      text: store.state.activity_texts,
      form_fields: store.state.activity,
      affected_form_fields: store.state.activity_affected
    }
  },
  metaInfo: {
    title: 'Activity'
  },
  computed: {
    next_step_text: function()  {

      if( this.is_loading )  {

        return 'Saving...';

      }

      return 'Continue';

    },
    total_affected: function()  {
      let affected = 0;
      for( let val in store.state.activity_affected ) {
        affected += store.state.activity_affected[ val ].value;
      }
      return affected.toString();
    }
  },
  methods: {

    toggle_loading: function()  {

      if( this.is_loading ) {

        this.is_loading = false;

      } else  {

        this.is_loading = true;

      }

    },
    save_step: function() {

      if( this.is_loading ) {

        return;

      }

      this.toggle_loading();

      store.dispatch( 'submit' ).then( result => {
// vrat mi novy token v ktorom bude post id
// uloz novy token
        // zmen akciu z new na edit (ak je new)
        console.log( result );
        this.toggle_loading();
        store.commit( 'update_submission', result.data.refresh_token );
        this.$router.push( { name: 'hazards' } );

      } );

      return false;

    },
    update_field: function( payload )  {

      store.commit( 'update_field', payload )

    }

  },
  mounted() {

    store.commit( 'mark_intro_as_read', true );
    store.commit( 'set_step', 2 );

  }
}
</script>

<style scoped>

</style>