<template>
  <section>
    <section class="block">
      <section>
        <h3 v-text="text.title"></h3>
        <p v-text="text.intro"></p>
      </section>
      <div v-for="hazard in hazards" :key="hazard.id">
        <Toggle :id="hazard.id" :state="hazard.state" :icon="hazard.icon" :title="hazard.title" :info="hazard.info" v-on:update_item="toggle_hazard"/>
      </div>
      <section class="button_row">
        <router-link v-bind:to="go_next" role="button" class="primary" v-show="can_go_forward">Continue</router-link>
        <a href="" role="button" class="primary inactive" v-show="!can_go_forward" v-on:click.prevent="pop">Continue</a>
        <router-link :to="{ name: 'activity'}" role="button">Back</router-link>
      </section>
    </section>
  </section>
</template>

<script>

import store from '../store/store.js';
import Toggle from "@/components/Toggle";

export default {
  name: "Hazards",
  components: { Toggle },
  metaInfo: {
    title: 'Choose Hazards'
  },
  data: function()  {

    return {

      hazards: store.state.hazards,
      text: store.state.hazards_texts

    }

  },
  computed: {

    can_go_forward : function() {

      return store.state.selected_hazards.length > 0;

    },
    intro_read : function() {

      return store.state.intro_read;

    },
    go_next : function()  {

      if( store.state.selected_hazards.length > 0 ) {

        return '/hazards/' + store.state.selected_hazards[ 0 ];

      } else  {

        return '';

      }

    }

  },
  methods: {

    pop : function()  {
      //
    },
    toggle_hazard: function( data ) {
      console.log('toggle', data);

      if( data.state === true ) {

        store.commit( 'enable_hazard', data.id );

      } else  {

        store.commit( 'disable_hazard', data.id );

      }

      store.commit( 'set_hazard_state', data );

    }

  },
  mounted() {

    store.commit( 'set_step', 3 );

  }

}
</script>

<style scoped>

</style>