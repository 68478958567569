<template>
  <div class="form_item input_checkbox">
    <Form_Label v-bind:name="name" v-bind:label="label" v-bind:info="info"/>
    <div class="input">
      <div v-for="( item, index ) in items" :key="item.id">
        <p v-if="item.type === 'checkbox'">
          <input type="checkbox" v-bind:id="item.id" v-bind:name="item.name" :checked="is_checked(item.value)" v-bind:value="item.value" @change="change_item({ value: $event.target.value, id: item.id, name: item.name, index: index, type: 'checkbox' })"/>
          <label :for="item.id">{{item.label}}</label>
        </p>
        <p v-if="item.type === 'text'">
          <input type="text" v-bind:id="item.id" v-bind:name="item.name" v-bind:value="item.value" @input="change_item({ value: $event.target.value, id: item.id, name: item.name, index: index, type: 'textfield' })"/>
          <button v-on:click="$emit( 'remove_item', { id: item.id, index: index, field_index: field_index })"><i class="icon-trash-2"></i></button>
        </p>
      </div>
      <div class="button_row">
        <a href="#" v-on:click.prevent="add_item" role="button" class="secondary">Add Item</a>
      </div>
    </div>
  </div>
</template>

<script>
import Form_Label from "@/components/Form_Label";

export default {
  name: "Input_Checkbox",
  components: { Form_Label },
  props: {
    label: String,
    name: String,
    info: String,
    field_index: Number,
    items: Array,
    checked_items: Array,
    additional_items: Array,
    store: String
  },
  methods: {

    is_checked : function( value )  {

      for( let i = 0; i < this.$props.checked_items.length; i++ ) {

        if( this.$props.checked_items[ i ] === value ) {

          return true;

        }

      }

      return false;

    },
    change_item: function( payload ) {
console.log('changed', payload);
      payload.store = this.store;
      payload.field_index = this.$props.field_index;
      this.$emit( 'update_item', payload );

    },
    add_item : function()  {

      this.$emit( 'add_item', {
        field_index: this.$props.field_index,
        name : this.$props.items[ 0 ].name
      } );

    }

  }

}
</script>

<style scoped>

</style>