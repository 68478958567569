<template>
  <div class="label">
    <label v-bind:for="name">{{label}}</label>
    <div class="info">{{info}}</div>
  </div>
</template>

<script>
export default {
  name: "Form_Label",
  props: {
    name: String,
    label: String,
    info: String
  }
}
</script>

<style scoped>

</style>