<template>
  <div class="form_item form_summary">
    <Form_Label v-bind:name="name" v-bind:label="label" v-bind:info="info"/>
    <div class="input">
      <p>{{value}}</p>
    </div>
  </div>
</template>

<script>
import Form_Label from "@/components/Form_Label";
export default {
  name: "Form_Summary",
  components: { Form_Label },
  props: {
    label: String,
    name: String,
    value: String,
    info: String
  }
}
</script>

<style scoped>

</style>