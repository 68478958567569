<template>
  <section>
    <section class="block">
      <section>
        <div class="hazard_navigation_header">
          <h3 v-text="text.title"></h3>
          <h3 class="pager">{{pager}}</h3>
        </div>
        <p v-text="text.intro"></p>
      </section>
      <section class="hazard_navigation">
        <router-link v-bind:to="go_prev" class="link"><i class="icon-arrow-left"></i></router-link>
        <div class="hazard_items" v-bind:class="{opened: items_opened}">
          <a href="#" class="hazard_item current_item" v-on:click.prevent="toggle_items">
            <img v-bind:src="current_hazard.icon" class="hazard_icon">
            <div class="hazard_description">
              <strong>{{current_hazard.title}}</strong>
              <em>{{current_hazard.info}}</em>
            </div>
            <i v-bind:class="{ 'icon-chevron-down' : !items_opened, 'icon-chevron-up' : items_opened }"></i>
          </a>
          <router-link class="hazard_item" v-bind:to="'/hazards/' + hazard.id" v-for="hazard in all_hazards" :key="hazard.id" v-show="items_opened" v-bind:class="{active: current_hazard.id === hazard.id }" v-on:click.native="toggle_items">
            <img v-bind:src="hazard.icon" class="hazard_icon">
            <div class="hazard_description">
              <strong>{{hazard.title}}</strong>
              <em>{{hazard.info}}</em>
            </div>
          </router-link>
        </div>
        <router-link v-bind:to="go_next" class="link"><i class="icon-arrow-right"></i></router-link>
      </section>
      <section class="form">
        <div v-for="( form_field, index ) in current_hazard.fields" :key="form_field.name">
          <component v-bind:is="get_component_name( form_field.type )" v-bind:message="form_field.html" v-if="get_component_name( form_field.type ) === 'Input_Message'"></component>
          <component v-bind:is="get_component_name( form_field.type )" v-else-if="get_component_name( form_field.type ) !== 'Hazid_Rating'" v-bind:rating="form_field.value"
                     v-bind:label="form_field.label" v-bind:name="form_field.name" :items="form_field.choices" v-bind:field_index="index" v-bind:checked_items="form_field.checked_items"
                     v-on:add_item="add_item" v-on:remove_item="remove_item" v-on:input="update_item" @update_item="update_item" v-bind:store="'hazards'"
          ></component>
          <component v-bind:is="get_component_name( form_field.type )" v-bind:field_index="index" v-bind:name="form_field.name" v-bind:items="form_field.choices" @update_item="update_item" v-else></component>
        </div>
      </section>
      <section class="button_row">
        <router-link v-bind:to="go_next" role="button" class="primary">Continue</router-link>
        <router-link v-bind:to="go_prev" role="button">Back</router-link>
      </section>
    </section>
  </section>
</template>

<script>
import store from "@/store/store";
import Input_Checkbox from "@/components/Input_Checkbox";
import Hazard_Rating from "@/components/Hazard_Rating";
import Input_Message from "@/components/Input_Message";

export default {
  name: "Hazard",
  components: { Hazard_Rating, Input_Checkbox, Input_Message },
  data: function()  {
    return {
      text: store.state.hazards_texts,
      id_helper: 100,
      items_opened : false
    }
  },
  metaInfo() {
    return {
      title: this.hazard_title
    }
  },
  computed: {

    hazard_title : function()  {

      if( store.state.current_hazard === null )  {

        return '';

      }

      return 'Hazard - ' + store.state.current_hazard.title;

    },
    current_hazard : function() {

      return store.state.current_hazard;

    },
    all_hazards : function()  {

      let selected = [];
      for( let i = 0; i < store.state.hazards.length; i++ ) {

        if( store.state.selected_hazards.indexOf( store.state.hazards[ i ].id ) !== -1 ) {

          selected.push( store.state.hazards[ i ] );

        }

      }

      return selected;

    },
    pager: function() {

      const idx = store.state.selected_hazards.indexOf( store.state.current_hazard.id ) + 1;
      const total = store.state.selected_hazards.length;

      return idx + ' of ' + total;

    },
    go_next : function()  {

      const idx = store.state.selected_hazards.indexOf( store.state.current_hazard.id );
      const len = store.state.selected_hazards.length - 1;

      if( idx === len ) {

        return  '/finish';

      }

      return '/hazards/' + store.state.selected_hazards[ idx + 1 ];

    },
    go_prev : function()  {

      const idx = store.state.selected_hazards.indexOf( store.state.current_hazard.id );

      if( idx === 0 ) {

        return  '/hazards';

      }

      return '/hazards/' + store.state.selected_hazards[ idx - 1 ];

    }

  },
  methods: {

    add_item: function( payload )  {

      const risk_id = payload.name.replace( '[]', '' ) + '_' + this.id_helper;
      this.id_helper += 1;

      payload.id = risk_id;

      store.commit( 'add_risk', payload );

    },
    remove_item : function( payload )  {

      store.commit( 'remove_risk', payload );

    },
    update_item : function( item )  {

      store.commit( 'update_risk', item );

    },
    toggle_items : function() {

      if( this.items_opened ) {

        this.items_opened = false;

      } else  {

        this.items_opened = true;

      }

    }

  },
  watch: {
    $route( to )  {

      store.commit( 'set_hazard', to.params.hazard );

      if( store.state.current_hazard === null ) {

        this.$router.push( { name: 'hazards' } );

      }

    // console.log('updated', to, from);
    }
  },
  beforeMount() {

    store.commit( 'set_hazard', this.$route.params.hazard );

    if( store.state.current_hazard === null ) {

      this.$router.push( { name: 'hazards' } );

    }

  }

}
</script>

<style scoped>

</style>