<template>
  <section class="block">
    <section>
      <h3 v-text="text.title"></h3>
      <p v-text="text.intro"></p>
    </section>
    <section class="form">
      <component v-bind:is=" get_component_name( form_field.type )" v-for="form_field in form_fields" :key="form_field.name"
                 v-bind:options="form_field.options" v-bind:label="form_field.label" v-bind:name="form_field.name" v-bind:value="form_field.value" v-bind:info="form_field.info"
                 v-on:changed="update_field" v-bind:store="'personal_details'"
      ></component>
    </section>
    <section class="button_row">
      <router-link :to="{ name: 'activity'}" role="button" class="primary">Continue</router-link>
      <router-link :to="{ name: 'intro'}" role="button">Back</router-link>
    </section>
  </section>
</template>

<script>
import Input_Text from "@/components/Input_Text";
import Input_Select from "@/components/Input_Select";
import store from "@/store/store";
import Input_Email from "@/components/Input_Email";
import Input_Textarea from "@/components/Input_Textarea";
import Input_Number from "@/components/Input_Number";
import Input_Date from "@/components/Input_Date";
import Input_Checkbox from "@/components/Input_Checkbox";

export default {
  name: "Personal_Details",
  components: { Input_Text, Input_Select, Input_Email, Input_Textarea, Input_Number, Input_Date, Input_Checkbox },
  data: function() {
    return {
      text: store.state.personal_details_texts,
      form_fields: store.state.personal_details
    }
  },
  metaInfo: {
    title: 'Personal Details'
  },
  methods:  {
    update_field: function( payload )  {

      store.commit( 'update_field', payload )

    },
  },
  mounted() {

    store.commit( 'mark_intro_as_read', true );
    store.commit( 'set_step', 1 );

  }
}
</script>

<style scoped>

</style>