import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import axios from 'axios';
import Intro from "@/pages/Intro";
import Personal_Details from "@/pages/Personal_Details";
import Activity from "@/pages/Activity";
import Hazards from "@/pages/Hazards";
import Hazard from "@/pages/Hazard";
import Finish from "@/pages/Finish";
import Error_Page from "@/pages/Error_Page";
import store from "@/store/store";
import VueLocalStorage from "vue-localstorage";
import Meta from 'vue-meta'

Vue.config.productionTip = false
Vue.config.API_URL = 'https://qmsdev.renfrewshireleisure.com/wp-json/tuna/api/hazid?t=';
// Vue.config.API_URL = 'http://localhost:86/index.php/wp-json/tuna/api/hazid?t=';

Vue.prototype.$http = axios;

Vue.use( VueLocalStorage );
Vue.use( VueRouter );
Vue.use( Meta );
Vue.mixin( {
  methods: {
    get_component_name: function( type )  {

      if( type === 'hazid_rating' ) {

        return 'Hazard_Rating';

      }

      return 'Input_' + type.charAt(0).toUpperCase() + type.slice( 1 );

    }

  }

} );

const routes = [{
    path: '/',
    name: 'intro',
    component: Intro
  },{
    path: '/personal-details',
    name: 'personal_details',
    component: Personal_Details
  }, {
    path: '/activity',
    name: 'activity',
    component: Activity
  },{
    path: '/hazards',
    name: 'hazards',
    component: Hazards
  },{
    path: '/hazards/:hazard',
    name: 'hazards',
    component: Hazard
  },{
    path: '/finish',
    name: 'finish',
    component: Finish
},{
  path: '/error',
  name: 'error',
  component: Error_Page
}]

export const router = new VueRouter({
  mode: 'history',
  routes: routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

router.beforeEach( async (to, from, next )=>{

  if( !store.state.data_loaded && to.name !== 'error' )  {

    let token = Vue.localStorage.get( 'token', null );

    if( typeof to.query.t !== 'undefined' ) {

      token = to.query.t;

    }

    await store.dispatch( 'init', {
      token: token
    } );

    next();

  } else  {

    next();

  }

});

new Vue({
  render: h => h(App),
  router: router,
}).$mount('#app');
