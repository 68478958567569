<template>
  <section class="toggle" v-bind:class="{ active : state === true, inactive : state === false }">
    <img v-bind:src="icon" class="toggle_icon">
    <div class="toggle_description">
      <strong>{{title}}</strong>
      <em>{{info}}</em>
    </div>
    <div class="toggle_controls">
      <div>
        <button v-on:click="enable">Yes</button>
        <button v-on:click="disable">No</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Toggle",
  props: {
    icon : String,
    title : String,
    info : String,
    id : String,
    state : Boolean
  },
  data: function()  {

    return {
      // state: null
    }

  },
  methods:  {

    enable : function() {

      // this.state = true;
      this.$emit( 'update_item', { state: true, id: this.id } );

    },
    disable : function()  {

      // this.state = false;
      this.$emit( 'update_item', { state: false, id: this.id } );

    }

  }
}
</script>

<style scoped>

</style>