<template>
  <div class="form_item input_text">
    <Form_Label v-bind:name="name" v-bind:label="label" v-bind:info="info"/>
    <div class="input">
      <input type="email" v-bind:id="name" v-bind:name="name" v-bind:value="value" @change="changed($event.target.value)"/>
    </div>
  </div>
</template>

<script>
import Form_Label from "@/components/Form_Label";

export default {
  name: "Input_Email",
  components: { Form_Label },
  props: {
    label: String,
    name: String,
    value: String,
    info: String,
    store: String
  },
  methods: {
    changed : function( value ) {
      this.$emit( 'changed', {
        store: this.store,
        name: this.name,
        value: value
      } );
    },
  }
}
</script>

<style scoped>

</style>