import Vue from "vue";
import Vuex from 'vuex';
import axios from 'axios';
import {router} from '../main.js';


Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        ajax_loading : false,
        data_loaded: false,
        intro_read: false,
        action: 'new',
        current_step: 0,
        steps: [{
            name: 'Start',
            id: 'intro'
        },{
            name: 'Personal Details',
            id: 'personal_details'
        },{
            name: 'Activity',
            id: 'activity'
        },{
            name: 'Hazards',
            id: 'hazards'
        },{
            name: 'Finish',
            id: 'finish'
        }],
        hazards: [],
        current_hazard : null,
        selected_hazards: [],
        excluded_hazards: [],
        activity: [],
        hazard_rating: null,
        finish: null,
        hazards_texts: null,
        start_texts: null,
        personal_details_texts: null,
        personal_details: [],
        activity_texts: null,
        activity_affected: [],
        submission_state: 'initial',
        submission_rating: null

    },
    mutations: {

        ajax_loading: ( state, payload )=>  {

            state.ajax_loading = payload;

        },

        update_field: ( state, payload ) => {

            console.log(payload.store, payload.name, payload.value);

            for( let i = 0; i < state[ payload.store ].length; i++ )   {
                if( state[ payload.store ][ i ].name === payload.name )    {
                    state[ payload.store ][ i ].value = payload.value;
                    break;
                }
            }

        },

        set_rating: ( state, payload ) =>   {

            state.submission_rating = payload;

        },

        add_risk: ( state, payload ) =>  {

            state.current_hazard.fields[ payload.field_index ].choices.push({
                id: payload.id,
                name: payload.name,
                value: '',
                label: '',
                type: 'text'
            });

            state.current_hazard.fields[ payload.field_index ].checked_items.push( payload.id );


        },

        remove_risk: ( state, payload ) =>  {

            state.current_hazard.fields[ payload.field_index ].choices.splice( payload.index, 1 );
            const idx = state.current_hazard.fields[ payload.field_index ].checked_items.indexOf( payload.id );
            state.current_hazard.fields[ payload.field_index ].checked_items.splice( idx, 1 );

        },
//todo pri niektorych riskoch sa ulozi ID pri niektorych value. treba to fixnut nech je to vzdy ID
        // myslim, ze toto som vyriesil na strane servera a momentalne to funguje
        update_risk: ( state, payload ) =>  {

            // may be a risk (textfield/checkbox) or hazard rating

            if( payload.type === 'textfield' )  {

                state.current_hazard.fields[ payload.field_index ].choices[ payload.index ].value = payload.value;

            } else if( payload.type === 'checkbox' )    {

                const idx = state.current_hazard.fields[ payload.field_index ].checked_items.indexOf( payload.id );

                if( idx === -1 )    {

                    state.current_hazard.fields[ payload.field_index ].checked_items.push( payload.id );

                } else  {

                    state.current_hazard.fields[ payload.field_index ].checked_items.splice( idx, 1 );

                }

            } else if( payload.type === 'hazard_rating' )   {

                state.current_hazard.fields[ payload.field_index ].value = payload.value;

            }

        },

        set_hazard: ( state, payload ) =>   {

            // verify that route is within selected hazards
            let found = false;
            for( let i = 0; i < state.selected_hazards.length; i++ )    {

                if( state.selected_hazards[ i ] === payload )    {

                    found = true;
                    break;

                }

            }

            if(!found)  {

                return;

            }

            for( let i = 0; i < state.hazards.length; i++ ) {

                if( state.hazards[ i ].id === payload ) {

                    state.current_hazard = state.hazards[ i ];
                    return;

                }

            }

        },
        set_hazard_state: (state, payload) => {

            for( let i = 0; i < state.hazards.length; i++ )    {

                if( state.hazards[ i ].id === payload.id )  {

                    state.hazards[ i ].state = payload.state
                    return

                }

            }

        },
        enable_hazard: ( state, payload ) => {


            for( let i = 0; i < state.selected_hazards.length; i++ )    {

                if( state.selected_hazards[ i ] === payload )  {

                    return

                }

            }

            state.selected_hazards.push( payload );

            for( let i = 0; i < state.excluded_hazards.length; i++ )    {

                if( state.excluded_hazards[ i ] === payload )  {

                    state.excluded_hazards.splice( i, 1 );
                    return

                }

            }

        },

        disable_hazard: ( state, payload ) => {

            for( let i = 0; i < state.excluded_hazards.length; i++ )    {

                if( state.excluded_hazards[ i ] === payload )  {

                    return

                }

            }

            state.excluded_hazards.push( payload );

            for( let i = 0; i < state.selected_hazards.length; i++ )    {

                if( state.selected_hazards[ i ] === payload )  {

                    state.selected_hazards.splice( i, 1 );
                    return

                }

            }

        },

        mark_intro_as_read: ( state ) => {

            state.intro_read = true;

        },
        set_step: ( state, payload ) => {

            if( payload < 1 )   {

                state.current_step = 0;
                return;

            }

            if( payload > state.steps.length - 1 )  {

                state.current_step = state.steps.length - 1;
                return;

            }

            state.current_step = payload;

        },
        set_submission_status:( state, payload ) => {

            state.submission_state = payload;

        },
        update_submission: ( state, payload )   =>  {

            state.action = 'edit';
            state.intro_read = true;
            console.log(payload);
            Vue.localStorage.set( 'token', payload );

        },
        set_initial_data: ( state, payload ) => {

            state.data_loaded = true;
            state.hazards = payload.data.hazards;
            state.selected_hazards = payload.data.selected_hazards;
            state.excluded_hazards = payload.data.excluded_hazards;
            state.activity = payload.data.activity;
            state.activity_affected = payload.data.activity_affected;
            state.hazard_rating = payload.data.hazard_rating;
            state.personal_details = payload.data.personal_details;

            // texts
            state.finish_texts = payload.data.texts.finish;
            state.hazards_texts = payload.data.texts.hazards;
            state.start_texts = payload.data.texts.start;
            state.activity_texts = payload.data.texts.activity;
            state.personal_details_texts = payload.data.texts.personal_details;

            if( payload.data.action === 'edit' )   {

                state.action = 'edit';
                state.intro_read = true;

            }

        }

    },
    actions: {
        init({ commit }, payload ){

            commit( 'ajax_loading', true );

            const promise = new Promise( ( resolve, reject ) => {

                const base_uri = Vue.config.API_URL + payload.token;

                axios.get( base_uri )
                    .then( ( result ) => {

                        // console.log( result );
                        Vue.localStorage.set( 'token', payload.token );
                        commit( 'set_initial_data', result );

                        resolve();

                    } ).catch( error => {

                        console.log('push_error');
                        router.push( '/error' );
                        reject( error );

                    } ).finally( () => commit( 'ajax_loading', false ) );

            } ).catch( error=> {
                console.log( error );
            } );
            return promise;

        },
        submit( { commit, state } )    {

            commit( 'ajax_loading', true );

            const promise = new Promise( ( resolve, reject ) => {

                const token = Vue.localStorage.get( 'token' );
                const base_uri = Vue.config.API_URL + token;

                if( state.action === 'new' )    {

                    axios.post( base_uri, state  )
                        .then( ( result ) => {
    // console.log(result);
                            // window.location.href = result.data.return_url;
                            resolve( result );

                        } ).catch( error => {

                            reject( error );
                            commit( 'set_submission_status', 'error' );

                        } ).finally( () => commit( 'ajax_loading', false ) );

                } else if( state.action === 'edit' )    {

                    axios.put( base_uri, state  )
                        .then( ( result ) => {
                            // console.log(result);
                            // window.location.href = result.data.return_url;
                            resolve( result );

                        } ).catch( error => {

                            reject( error );
                            commit( 'set_submission_status', 'error' );

                        } ).finally( () => commit( 'ajax_loading', false ) );

                }


            } ).catch( error=> {

                console.log( error );

            } );
            return promise;

        }
    }

});