<template>
  <section>
    <section class="block">
      <section>
        <h3 v-text="text.part_1_title"></h3>
        <div v-html="text.part_1_text"></div>
      </section>
      <section v-show="!intro_read" class="nochrome">
        <a href="" role="button" class="primary" v-on:click.stop.prevent="mark_intro_as_read">Continue</a>
      </section>
      <section v-show="intro_read">
        <h3 v-text="text.part_2_title"></h3>
        <div v-html="text.part_2_text"></div>
      </section>
      <section v-show="intro_read">
        <router-link :to="{ name: 'personal_details'}" role="button" class="primary">Continue</router-link>
      </section>
    </section>
  </section>
</template>

<script>

import store from '../store/store.js';

export default {
  name: "Intro",
  data: function()  {
    return  {
      text: store.state.start_texts,
    }
  },
  computed: {

    intro_read : function() {

      return store.state.intro_read;

    }

  },
  methods: {

    mark_intro_as_read : function() {

      store.commit( 'mark_intro_as_read' );

    }

  },
  mounted() {

    store.commit( 'set_step', 0 );

  }

}
</script>

<style scoped>

</style>