<template>
  <p v-html="message"></p>
</template>

<script>
export default {
  name: "Input_Message",
  props: {
    message : String
  }
}
</script>

<style scoped>

</style>