<template>
  <div class="form_item input_date">
    <Form_Label v-bind:name="name" v-bind:label="label" v-bind:info="info"/>
    <div class="input">
      <datepicker :value="value" :name="name" :calendar-button="true" :calendar-class="'datepicker'" :calendar-button-icon="'icon-calendar'" @input="changed"></datepicker>
    </div>
  </div>
</template>

<script>
import Form_Label from "@/components/Form_Label";
import Datepicker from 'vuejs-datepicker';
import dateFormat from 'dateformat';

export default {
  name: "Input_Date",
  components: { Datepicker, Form_Label},
  props: {
    label: String,
    name: String,
    value: String,
    info: String,
    store: String
  },
  data: function()  {
    return  {
      date: null
    }
  },
  methods: {
    changed : function( value ) {
      this.$emit( 'changed', {
        store: this.store,
        name: this.name,
        value: dateFormat( value, 'yyyy-mm-dd' )
      } );
    },
  }
}
</script>

<style scoped>

</style>