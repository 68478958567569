<template>
  <div class="form_item input_number">
    <Form_Label v-bind:name="name" v-bind:label="label" v-bind:info="info"/>
    <div class="input">
      <div class="faux_input" v-bind:class="{ active : has_focus }">
        <a href="#step_down" v-on:click.prevent="step_down"><i class="icon-minus"></i></a>
        <input type="number" v-bind:id="name" v-bind:name="name" v-bind:value="value" @focus="toggle_outline" @blur="toggle_outline" @change="changed($event.target.value)">
        <a href="#step_up" v-on:click.prevent="step_up"><i class="icon-plus"></i></a>
      </div>
    </div>
  </div>
</template>

<script>
import Form_Label from "@/components/Form_Label";

export default {
  name: "Input_Number",
  components: { Form_Label },
  props: {
    label: String,
    name: String,
    value: Number,
    info: String,
    store: String
  },
  data: function()  {
    return  {
      has_focus: false
    }
  },
  methods: {

    changed : function( value ) {

      this.$emit( 'changed', {
        store: this.store,
        name: this.name,
        value: parseInt( value )
      } );

    },
    toggle_outline : function() {

      if( this.has_focus )  {

        this.has_focus = false;

      } else  {

        this.has_focus = true;

      }

    },
    step_up : function()  {

      this.$emit( 'changed', {
        store: this.store,
        value: this.value + 1,
        name: this.name
      } );

    },
    step_down : function()  {

      this.$emit( 'changed', {
        store: this.store,
        value: this.value - 1,
        name: this.name
      } );

    }

  }

}
</script>

<style scoped>

</style>