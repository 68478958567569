<template>
  <div class="hazard_rating">
    <div class="occurrence" v-if="is_occurrence">
      <a href="#" class="item" v-for="choice in items" :key="choice" v-on:click.prevent="set_rating( choice )" v-bind:class="{ active: rating === choice }">
        <img v-bind:src="text[choice].icon">
        <strong v-text="text[choice].title"></strong>
        <p v-text="text[choice].desc"></p>
      </a>
    </div>
    <div class="severity" v-if="is_severity">
      <a href="#" class="item" v-for="choice in items" :key="choice" v-on:click.prevent="set_rating( choice )" v-bind:class="{ active: rating === choice }">
        <img v-bind:src="text[choice].icon">
        <strong v-text="text[choice].title"></strong>
        <p v-text="text[choice].desc"></p>
      </a>
    </div>
  </div>
</template>

<script>
import store from "@/store/store";

export default {
  name: "Hazard_Rating",
  props: {
    name : String,
    rating : String,
    items : Array,
    store: String,
    field_index: Number
  },
  computed: {

    is_severity : function()  {

      return this.name.indexOf( 'severity' ) !== -1;

    },
    is_occurrence : function() {

      return this.name.indexOf( 'occurrence' ) !== -1;

    },
    text : function() {

      if( this.name.indexOf( 'severity' ) !== -1 )  {

        return store.state.hazard_rating.severity;

      }

      return store.state.hazard_rating.occurrence;

    }

  },
  methods: {

    set_rating: function( rating )  {

      const payload = {
        type : 'hazard_rating',
        field_index : this.$props.field_index,
        value : rating
      };

      this.$emit( 'update_item', payload );

    }

  }
}
</script>

<style scoped>

</style>