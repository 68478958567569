<template>
  <header>
    <div class="logo">
      <img src="../assets/renfrewshire_leisure_logo.png"  alt="Hazid Report Builder"/>
      <h1><span>HAZID</span> <span>Report Builder</span></h1>
      <span>HAZID</span>
    </div>
    <nav class="form_navigation">
        <div class="step" v-for="( step, index ) in steps" :key="step.id" v-bind:class="{ 'active' : index === current_step, 'visited' : index < current_step }">
          <a href="">{{ step.name }}</a>
        </div>
    </nav>
<!--      <div class="step visited">-->
<!--        <a href="">Personal Details</a>-->
<!--      </div>-->
<!--      <div class="step active">-->
<!--        <a href="">Activity</a>-->
<!--      </div>-->
<!--      <div class="step">-->
<!--        <a href="">Hazards</a>-->
<!--      </div>-->
<!--      <div class="step">-->
<!--        <a href="">Finish</a>-->
<!--      </div>-->
  </header>
</template>

<script>
import store from '../store/store.js';

export default {
  name: "Header",
  data: function()  {

    return {
      steps: store.state.steps
    }

  },
  computed: {

    current_step: function()  {

      return store.state.current_step;

    }

  }
}
</script>

<style scoped>

</style>