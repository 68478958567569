<template>
  <section>
    <section class="block">
      <section>
        <h3>Error occurred</h3>
        <div>Please return back to your dashboard and try launching the HAZID builder again</div>
      </section>
    </section>
  </section>
</template>

<script>
export default {
  name: "Error_Page",
  metaInfo: {
    title: 'Error'
  },
}
</script>

<style scoped>

</style>