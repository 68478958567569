<template>
  <section>

    <section class="block">

      <section>
        <h3 v-text="text.title"></h3>
        <p v-text="text.intro"></p>
      </section>

      <section>
        <div v-html="text.text"></div>
        <div class="form_rating">
          <a href="#" class="form_rating_item" v-on:click.prevent="set_rating(5)" v-bind:class="{active: rating === 5 }">
            <img src="../assets/rating_5.png">
          </a>
          <a href="#" class="form_rating_item" v-on:click.prevent="set_rating(4)" v-bind:class="{active: rating === 4 }">
            <img src="../assets/rating_4.png">
          </a>
          <a href="#" class="form_rating_item" v-on:click.prevent="set_rating(3)" v-bind:class="{active: rating === 3 }">
            <img src="../assets/rating_3.png">
          </a>
          <a href="#" class="form_rating_item" v-on:click.prevent="set_rating(2)" v-bind:class="{active: rating === 2 }">
            <img src="../assets/rating_2.png">
          </a>
          <a href="#" class="form_rating_item" v-on:click.prevent="set_rating(1)" v-bind:class="{active: rating === 1 }">
            <img src="../assets/rating_1.png">
          </a>
        </div>
      </section>

      <section class="button_row">
        <a href="#" role="button" class="primary" v-bind:class="{ loading: is_loading }" @click.prevent="submit" v-text="next_step_text"></a>
        <router-link v-bind:to="go_prev" role="button">Back</router-link>
      </section>

    </section>

  </section>

</template>

<script>

import store from '../store/store.js';

export default {
  name: "Finish",
  data: function()  {
    return {
      is_loading: false,
      text: store.state.finish_texts
    }
  },
  metaInfo: {
    title: 'Finish'
  },
  computed: {

    rating : function() {

      return store.state.submission_rating;

    },

    next_step_text: function()  {

      if( this.is_loading )  {

        return 'Saving...';

      }

      return 'Submit';

    },
    go_prev : function()  {
      return '/hazards/' + store.state.selected_hazards[ store.state.selected_hazards.length - 1 ];

    }

  },
  methods: {

    toggle_loading: function()  {

      if( this.is_loading ) {

        this.is_loading = false;

      } else  {

        this.is_loading = true;

      }

    },
    submit : function() {

      if( this.is_loading ) {

        return;

      }

      this.toggle_loading();

      store.dispatch( 'submit' ).then( result => {

        window.location.href = result.data.return_url;

      } );

      return false;

    },
    set_rating : function( rating ) {

      store.commit( 'set_rating', rating );

    }

  },
  mounted() {

    store.commit( 'set_step', 4 );

  }

}
</script>

<style scoped>

</style>