<template>
  <div class="form_item input_select">
    <Form_Label v-bind:name="name" v-bind:label="label" v-bind:info="info"/>
    <div class="input">
      <select v-bind:id="name" v-bind:name="name" @change="changed($event.target.value)">
        <option v-for="option in options" :key="option.value" v-bind:value="option.value">{{ option.label }}</option>
      </select>
    </div>
  </div>
</template>

<script>
import Form_Label from "@/components/Form_Label";

export default {
  name: "Input_Select",
  components: {Form_Label},
  props: {
    label: String,
    name: String,
    value: String,
    info: String,
    options: Array,
    store: String
  },
  methods: {
    changed : function( value ) {
      this.$emit( 'changed', {
        store: this.store,
        name: this.name,
        value: value
      } );
    },
  }
}
</script>

<style scoped>

</style>